<template>
    <div class="expTask-container" style="">
        <div class="top"></div>
        <div class="content">
            <Header :title="'可能经验'" :bgColor="'rgba(255,255,255,0)'" :leftArrowType="1" :titleColor="'#ffffff'"
                :leftClick="goBack">
            </Header>
            <div style="display: flex;width: 100%;padding: 10px 15px;box-sizing: border-box;position: relative;">
                <div style="flex: 1;width: 100%;padding-right: 15px;box-sizing: border-box;;">
                    <div
                        style="cursor: pointer;;display: inline-block;background-color: #433E84;border-radius: 22px;color: white;font-size: 16px;padding: 5px 12px;">
                        {{
                            userNickName != '' ? userNickName : '--' }}</div>
                    <p style=";color: white;font-size: 14px;padding: 5px 12px;margin-top:5px">{{ userInfo.user_mobile }}</p>

                    <div style="margin-top: 10px;display: flex;width: 100%;align-items: center;">
                        <el-progress :percentage="exprogress" color="#FFCF98" style="flex: 1;padding-right: 5px;"
                            :show-text="false"></el-progress>
                        <p style="color: white;font-size: 8px;">经验值：{{ exp_points + '/' + nextLevel }}</p>
                    </div>
                </div>
                <div style="position: relative;width: 66px;cursor: pointer;">
                    <img src="../../assets/app/icon_default_avator.png"
                        style="width: 66px;height: 66px;position: absolute;">
                    <div
                        style="position: absolute;bottom: 5px;left: 50%;transform: translateX(-50%);background-color: #FFAF53;color: white;font-size: 10px;border-radius: 7px;border: 2px solid white;padding: 2px 8px;">
                        {{ userInfo.exp_level ? 'Lv.' + userInfo.exp_level : '--' }}
                    </div>
                </div>
            </div>

            <div
                style="overflow-y: auto;padding: 20px;margin-top: 25px;background-color: white;;flex: 1;width: 100%;border-top-left-radius: 20px;border-top-right-radius: 20px;box-sizing: border-box;">
                <div style="display: flex;">
                    <div :class="currentTab == 0 ? 'tab-item-selected' : 'tab-item'" @click="currentTab = 0">
                        <p>经验记录</p>
                        <img v-if="currentTab == 0" src="../../assets/app/album_list_slider.png"
                            style="width: 35px;height: auto;object-fit: cover;">
                    </div>
                    <div :class="currentTab == 1 ? 'tab-item-selected' : 'tab-item'" @click="currentTab = 1">
                        <p>经验任务</p>
                        <img v-if="currentTab == 1" src="../../assets/app/album_list_slider.png"
                            style="width: 35px;height: auto;object-fit: cover;">
                    </div>
                </div>
                <div style="margin-top: 20px;" v-if="currentTab == 0">
                    <div v-for="(record, index) in expRecords" :key="index" class="record-item">
                        <img :src="'http://' + record.cover_pic_address"
                            style="width: 44px;height: 44px;object-fit: cover;">
                        <div style="flex: 1;padding: 0px 15px;width: 100%;box-sizing: border-box;">
                            <p style="color:#3D3D74;font-size: 16px;">{{ record.task_info.name }}</p>
                            <p style="color: #999999;font-size: 14px;margin-top: 10px;">{{
                                formatRecordTime(record.create_time) }}</p>
                        </div>
                        <p style="color: #ffaf53;font-size:16px ;">{{ '+' + record.expo_points }}</p>
                    </div>
                </div>
                <div style="margin-top: 20px;" v-if="currentTab == 1">
                    <div v-for="(category, index) in expTasks" :key="index" class="category-item">
                        <div style="display: flex;">
                            <div
                                style="width: 5px;height: 16px;background-color: #5d5bb5;border-top-right-radius:100px;border-bottom-right-radius:100px ;">
                            </div>
                            <p style="margin-left: 15px;color: #5d5bb5;font-size: 18px;font-weight: bold;">{{
                                category.category }}</p>
                        </div>
                        <div style="margin-top: 15px;width: 100%;padding:0px 20px;box-sizing: border-box;">
                            <div v-for="(task, taskIndex) in category.taskList" :key="taskIndex"
                                style="display: flex;align-items: center;">
                                <img :src="'http://' + task.cover_pic_address"
                                    style="width: 44px;height: 44px;object-fit: cover;">
                                <div style="flex: 1;width: 100%;padding: 0px 15px;box-sizing: border-box;">
                                    <p style="color: #3D3D74;font-size: 16px;">{{ task.name }}</p>
                                    <p style="color: #999999;font-size: 12px;margin-top: 5px;">{{ task.intro }}</p>
                                </div>
                                <p style="color: #ffaf53;font-size: 16px;">{{ '+' + task.exp_points }}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import Header from "../../components/app/Header.vue"

import { getUserByUserID, findKidsByUser, getNextLevelPoints, getExpTaskRecordsV1, getExpTaskCategories, getExpTaskList } from "../../api/app"
import { Loading } from "element-ui";
import {
    formatDate
} from '../../utils/date'
import {
    getAppUserId
} from '../../utils/store'
export default ({
    name: 'index',
    components: {
        Header,
    },
    data() {
        return {
            userInfo: {},
            kid_id: '',
            userNickName: '',
            nextLevel: 0,
            exp_points: 0,
            exprogress: 0,

            currentTab: 0,

            expRecords: [],
            expTasks: [],
        }
    },
    mounted() {
        this.requestUserInfo()
    },
    methods: {

        goBack() {
            this.$router.go(-1)
        },

        formatRecordTime(time) {
            return formatDate(new Date(time * 1000), 'Y-MM-dd hh:mm:ss')
        },

        async requestUserInfo() {
            let loadingInstance = Loading.service({
                fullscreen: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.8)",
            });

            let expRecordResult = await getExpTaskRecordsV1(getAppUserId())
            this.expRecords = expRecordResult.data.data

            let taskCategoriesResult = await getExpTaskCategories()
            let taskCategories = taskCategoriesResult.data.data

            for (let i = 0; i < taskCategories.length; i++) {
                let category = taskCategories[i]
                let taskListResult = await getExpTaskList(category)
                let obj = {
                    category: category,
                    taskList: taskListResult.data.data
                }
                this.expTasks.push(obj)
            }

            let userInfoResult = await getUserByUserID(getAppUserId())

            if (userInfoResult.data.code == 0) {
                this.userInfo = userInfoResult.data.data
                this.exp_points = this.userInfo.exp_points
                this.getNextLevelPoints(this.userInfo.exp_level)
            }

            let kidsResult = await findKidsByUser(getAppUserId())

            if (kidsResult.data.code == 0) {
                let result = kidsResult.data.data
                for (let i = 0; i < result.length; i++) {
                    let content = result[i]
                    if (content.kid_name && content.kid_name != '') {
                        this.userNickName = content.kid_name
                        this.kid_id = content._id.$id
                        break
                    }
                }
            }
            loadingInstance.close()
        },
        getNextLevelPoints(exp_level) {
            getNextLevelPoints(exp_level).then((res) => {
                let result = res.data
                this.nextLevel = result
                this.exprogress = parseInt((this.exp_points / this.nextLevel) * 100)
            })
        }
    }
})
</script>

<style lang="less" scoped>
.category-item {
    width: 100%;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 4px 20px rgba(52, 50, 128, 0.27);
    border-radius: 16px;
    padding: 15px 0px;
    margin-bottom: 25px;
}

.record-item {
    align-items: center;
    width: 100%;
    display: flex;
    padding: 15px 0px;
    box-sizing: border-box;
    border-bottom: 1px solid #EEEEEE;
}

.tab-item {
    cursor: pointer;
    flex: 1;
    text-align: center;
    font-size: 20px;
    color: #9698A2;
}

.tab-item-selected {
    .tab-item();
    color: #000000;
}

.expTask-container {
    position: relative;
    width: 100vw;
    height: 100vh;

    .top {
        position: absolute;
        width: 100%;
        height: 245px;
        background-image: url('../../assets//app/bg_my_top.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100%;
    }

    .content {
        width: 100vw;
        height: 100vh;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
</style>